import React from 'react'
import { css } from '@emotion/core'
import { color, mediaQuery, smartPhone } from '../constants/styles'

const container = css`
  background: ${color.white};
  display: flex;
  align-items: center;

  padding: 10px;
  min-width: 120px;
  ${mediaQuery[smartPhone]} {
    min-width: 96px;
    padding: 8px;
  }
`

const municipalText = css`
  margin-left: 10px;
`

export const MunicipalForm = (props) => {
  return props.municipal.map((data, index) => {
    return (
      <label key={index} css={container}>
        <input type="checkbox" name="municipal" value={data} />
        <div css={municipalText}>{data}</div>
      </label>
    )
  })
}
