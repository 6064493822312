import React from "react";
import { css } from "@emotion/core";
import { OpeningListItem } from "./OpeningListItem";
import { mediaQuery, smartPhone } from "../constants/styles";

const container = css`
  width: 80%;
  margin: 0 auto;
  ${mediaQuery[smartPhone]} {
    width: 90%;
  }
`;

export const OpeningListItems = (data) => {
  const openingListData = data.props.allMicrocmsOpeninglist.nodes;
  const { municipalArr, sectionsArr } = data;
  return openingListData?.map((data, index) => {
    return (
      <div key={data.id}>
        <div css={container}>
          <OpeningListItem
            storeName={data.storeName}
            municipal={data.municipal}
            sections={data.sections}
            index={index + 1}
            municipalArr={municipalArr}
            sectionsArr={sectionsArr}
          />
        </div>
      </div>
    );
  });
};
