import React, { useState, useEffect } from "react";
import { graphql } from "gatsby";
import { Layout } from "../components/Layout";
import { Head } from "../components/Head";
import { SearchOpeningList } from "../components/SearchOpeningList";
import { css } from "@emotion/core";

const container = css`
  padding: 48px 0;
`;

export default function OpeningList({ data, location }) {
  const siteMetadata = data?.site?.siteMetadata
  const title = "開業一覧";
  const [pathname, setPathname] = useState("");
  useEffect(() => {
    setPathname(location.pathname);
  }, []);
  return (
    <Layout location={pathname}>
      <Head props={siteMetadata} />
      <div css={container}>
        <SearchOpeningList props={data} />
      </div>
    </Layout>
  );
}

export const query = graphql`
  {
    site {
      siteMetadata {
        title,
        siteUrl,
        description
      }
    }
    allMicrocmsOpeninglist {
      nodes {
        id
        municipal
        sections
        storeName
      }
    }
  }
`;
