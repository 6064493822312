import React from 'react'
import { css } from '@emotion/core'
import { color, mediaQuery, smartPhone } from '../constants/styles'

const container = css`
  background: ${color.white};
  display: flex;
  align-items: center;
  padding: 16px;
  min-width: 120px;
  ${mediaQuery[smartPhone]} {
    min-width: 96px;
    padding: 8px;
  }
`

const sectionText = css`
  margin-left: 10px;
`

export const SectionsForm = (props) => {
  return props.sections.map((data, index) => {
    return (
      <label key={index} css={container}>
        <input type="checkbox" name="sections" value={data} />
        <div css={sectionText}>{data}</div>
      </label>
    )
  })
}
