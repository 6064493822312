import React from 'react'
import { Link } from 'gatsby'
import { css } from '@emotion/core'
import { font, color } from '../constants/styles'
import { SectionsList } from './SectionsList'

const displayNone = css`
  display: none;
`

const wrapper = css`
  background: ${color.whiteGray};
  line-height: 2;
  padding: 20px 60px;
  margin: 24px 0;
`
const title = css`
  font-size: ${font.subtitleSize};
  font-family: ${font.TitleFontFamily};
  font-weight: Bold;
`
const address = css`
  padding-bottom: 8px;
  font-size: ${font.textSize};
`

const sectionContainer = css`
  display: flex;
  flex-wrap: wrap;
  font-size: ${font.textSize};
`

export const OpeningListItem = (props) => {
  const {
    storeName,
    municipal,
    sections,
    index,
    municipalArr,
    sectionsArr,
  } = props
  const matchData = 0

  let matchMunicipalResult = 0
  if (municipalArr.length !== 0) {
    municipalArr.map((data) => {
      if (data === municipal[matchData]) {
        return (matchMunicipalResult += 1)
      } else {
        return (matchMunicipalResult += 0)
      }
    })
  } else {
    return matchMunicipalResult
  }

  let matchSectionResult = 0
  if (sectionsArr.length !== 0) {
    sections.map((data) => {
      for (let i = 0; i < sectionsArr.length; i++) {
        if (data === sectionsArr[i]) {
          matchSectionResult += 1
        } else {
          matchSectionResult += 0
        }
      }
    })
  } else {
    return matchSectionResult
  }
  const isMatch = matchMunicipalResult !== 0 && matchSectionResult !== 0
  return (
    <section css={isMatch ? wrapper : displayNone}>
      <Link to={`Details/${index}`}>
        <p css={title}>{storeName}</p>
        <p css={address}>{municipal}</p>
        <div css={sectionContainer}>
          <SectionsList props={sections} />
        </div>
      </Link>
    </section>
  )
}
