import React from 'react'
import { css } from '@emotion/core'
import { font, color } from '../constants/styles'

const button = css`
  display: inline-block;
  border-radius: 4px;
  background-color: ${color.mainD};
  color: ${color.white};
  margin-bottom: 20px;
  padding: 10px 40px;
  font-size: ${font.miniTextSize};
  :hover {
    background-color: ${color.white};
    cursor: pointer;
    color: ${color.mainD};
  }
`

const button2 = css`
  display: inline-block;
  border-radius: 4px;
  background-color: ${color.white};
  color: ${color.mainD};
  padding: 10px 40px;
  font-size: ${font.miniTextSize};
  :hover {
    background-color: ${color.mainD};
    color: ${color.white};
    cursor: pointer;
  }
`

const span = css`
  padding: 16px;
`

export const MunicipalFormButtons = () => {
  const AllCheck = () => {
    for (let i = 0; i < document.form.municipal.length; i++) {
      document.form.municipal[i].checked = true
    }
  }

  const AllClear = () => {
    for (let i = 0; i < document.form.municipal.length; i++) {
      document.form.municipal[i].checked = false
    }
  }

  return (
    <span>
      <button css={button} name="all" onClick={AllCheck} type="button">
        すべて選択
      </button>
      <span css={span}></span>
      <button css={button2} name="all" onClick={AllClear} type="button">
        すべて解除
      </button>
    </span>
  )
}
