import React from 'react'
import { css } from '@emotion/core'
import { color } from '../constants/styles'

const wrapper = css`
  padding: 0 16px 12px 0;
`

const container = css`
  padding: 4px 16px;
  border-radius: 4px;
  background: ${color.black};
  color: ${color.white};
  white-space: nowrap;
`

export const SectionsList = (data) => {
  return data.props.map((data, index) => {
    return (
      <div css={wrapper} key={index}>
        <div css={container}>{data}</div>
      </div>
    )
  })
}
